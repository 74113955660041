var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smb-client-wrapper"},[(_vm.isLoading)?_c('Loader'):[_c('SmbTitles',{attrs:{"is-show-title":"","is-show-sub-title":"","title":"Testimonial","sub-title":"We will automatically take reviews from your Zillow page."}}),_c('div',{staticClass:"testimonials"},[_c('div',{staticClass:"testimonials__title"},[_vm._v("Zillow link")]),_c('SmbInput',{attrs:{"valueProp":_vm.zillowLink,"placeholder":"E.g. www.zillow.com/profile/Social%20Wonder"},on:{"propWasUpdated":_vm.propWasUpdatedHandler}}),_c('div',{staticClass:"testimonials__bottom-text"},[_vm._v(" We are working on adding support for Google Reviews, Yelp and other websites. ")])],1),_c('div',{staticClass:"footer-buttons"},[_c('SmbActionBtn',{staticClass:"arrow-button",attrs:{"isUseIcon":"","iconName":"mdi-arrow-left","width":"20%"},on:{"click":function($event){return _vm.$router.push({ name: 'generate-content' })}}}),_c('SmbActionBtn',{attrs:{"width":"80%","isLoading":_vm.isLoadingGenerateBtn,"text":_vm.isAllowZillowLink
            ? _vm.isRedirectedFromGeneratedPage
              ? 'Generate'
              : 'Save'
            : 'Cancel',"emitName":_vm.isAllowZillowLink ? 'generate' : 'cancel'},on:{"cancel":function($event){return _vm.$router.push({ name: 'generate-content' })},"generate":_vm.addZillowLinkToCustomerSettings}})],1)],_c('SmbAddBrandingDetailsPopUp',{attrs:{"isShow":_vm.isShowBrandingPopUp,"actionType":_vm.isNeedToUpdateBranding,"buttonsData":{
      button: _vm.lastClickedBtnData,
      type: _vm.getCurrentCreationContentType,
    },"emit-name":_vm.emitNameForActionAfterPopup,"nameOfPageWhereWillRedirect":"generate-content"},on:{"close":function($event){_vm.isShowBrandingPopUp = false},"testimonialHandler":function($event){return _vm.createCalendarPostHandler()}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }