<template>
  <div class="smb-client-wrapper">
    <Loader v-if="isLoading" />

    <template v-else>
      <SmbTitles
        is-show-title
        is-show-sub-title
        title="Testimonial"
        sub-title="We will automatically take reviews from your Zillow page."
      />
      <div class="testimonials">
        <div class="testimonials__title">Zillow link</div>
        <SmbInput
          :valueProp="zillowLink"
          placeholder="E.g. www.zillow.com/profile/Social%20Wonder"
          @propWasUpdated="propWasUpdatedHandler"
        />

        <div class="testimonials__bottom-text">
          We are working on adding support for Google Reviews, Yelp and other
          websites.
        </div>
      </div>
      <div class="footer-buttons">
        <SmbActionBtn
          class="arrow-button"
          isUseIcon
          iconName="mdi-arrow-left"
          width="20%"
          @click="$router.push({ name: 'generate-content' })"
        />
        <SmbActionBtn
          width="80%"
          :isLoading="isLoadingGenerateBtn"
          :text="
            isAllowZillowLink
              ? isRedirectedFromGeneratedPage
                ? 'Generate'
                : 'Save'
              : 'Cancel'
          "
          :emitName="isAllowZillowLink ? 'generate' : 'cancel'"
          @cancel="$router.push({ name: 'generate-content' })"
          @generate="addZillowLinkToCustomerSettings"
        />
      </div>
    </template>
    <SmbAddBrandingDetailsPopUp
      :isShow="isShowBrandingPopUp"
      :actionType="isNeedToUpdateBranding"
      :buttonsData="{
        button: lastClickedBtnData,
        type: getCurrentCreationContentType,
      }"
      :emit-name="emitNameForActionAfterPopup"
      @close="isShowBrandingPopUp = false"
      nameOfPageWhereWillRedirect="generate-content"
      @testimonialHandler="createCalendarPostHandler()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  TIPS_MENU,
  SUBJECT_IDS,
  GENERATE_CONTENT_BUTTONS,
} from "@/constants/smb-client";
import smbMethods from "@/mixins/smbMethods";

import Loader from "@/components/global/Loader";
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbInput from "@/components/smb-client/SmbInput";
import SmbAddBrandingDetailsPopUp from "@/components/smb-client/SmbAddBrandingDetailsPopUp.vue";
import H from "@/utils/helper";
import LogRocket from "logrocket";

export default {
  name: "Tips",
  components: {
    Loader,
    SmbTitles,
    SmbActionBtn,
    SmbInput,
    SmbAddBrandingDetailsPopUp,
  },
  data: () => ({
    menuTips: TIPS_MENU,
    isLoading: false,
    subjectId: SUBJECT_IDS.TESTIMONIAL.GENERAL,
    zillowLink: "",
    lastClickedBtnData: {},
    isLoadingGenerateBtn: false,
    isShowBrandingPopUp: false,
    emitNameForActionAfterPopup: "",
  }),
  mixins: [smbMethods],
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("creationContentType", ["getCurrentCreationContentType"]),
    isCanGenerate() {
      return this.zillowLink.trim() !== "";
    },
    isAllowZillowLink() {
      return !!this.isZillowProfileLink(this.zillowLink);
    },
    isRedirectedFromGeneratedPage() {
      return this.$route.params && this.$route.params.isFromGenerated;
    },
    popUpTextFirstPath() {
      return `The new testimonial ${this.getCurrentCreationContentType}  is now being generated.`;
    },
    popUpTextSecondPath() {
      return this.getCurrentSmbCustomer.primary_phone
        ? `You will be notified via SMS to ${H.formatPhoneNumber(
            this.getCurrentSmbCustomer.primary_phone,
            false
          )} once ready (about 1 min).`
        : "You can check it out in the Pending content section in ~30 seconds.";
    },
    popUpText() {
      return `${this.popUpTextFirstPath} ${this.popUpTextSecondPath}`;
    },
  },
  created() {
    this.zillowLink = this.getCurrentSmbCustomer.zillow_page;
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["createPosts", "fetchSmbClientInfo"]),
    ...mapMutations("smbClientSelection", ["setPopupSettings"]),
    propWasUpdatedHandler(value) {
      this.zillowLink = value;
    },
    isZillowProfileLink(text) {
      const expression =
        /(www\.)?zillow\.[a-zA-Z0-9()]{1,6}\b\/profile\/([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
      const regex = new RegExp(expression);
      return !!text.match(regex);
    },
    async addZillowLinkToCustomerSettings() {
      this.isLoadingGenerateBtn = true;
      try {
        const params = {
          customerId: this.getCurrentSmbCustomer.id,
          params: {
            zillowPageSaas: this.zillowLink,
          },
        };

        await this.updateCustomer(params);
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        if (this.isRedirectedFromGeneratedPage) {
          if (this.isNeedToUpdateBranding) {
            this.isShowBrandingPopUp = true;
            this.emitNameForActionAfterPopup = "testimonialHandler";
            this.lastClickedBtnData = GENERATE_CONTENT_BUTTONS.find(
              (button) => button.id === 7
            );
          } else {
            await this.createCalendarPostHandler();
          }
        } else {
          await this.$router.push({ name: "smb-client" });
        }
      } catch (e) {
        throw e;
      } finally {
        this.isLoadingGenerateBtn = false;
      }
    },
    async createCalendarPostHandler() {
      this.isShowBrandingPopUp = false;
      LogRocket.track("content_generation_button");
      this.isLoadingGenerateBtn = true;
      try {
        let params = {
          customerId: this.getCurrentSmbCustomer.id,
          topicSubId: [this.subjectId],
          bySmb: true,
          type: this.getCurrentCreationContentType,
        };
        await this.createPosts(params);
        this.setPopupSettings({
          isShowPopup: true,
          popUpText: this.popUpText,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
      this.isLoadingGenerateBtn = false;
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f3f2f3;
  height: 100%;
  .testimonials {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    &__title {
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
    }
    &__bottom-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      text-align: center;
      margin-top: auto;
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    position: relative;
    @media screen and (min-width: 760px) {
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
    }
    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 100%;
        max-width: 315px;
      }
    }
    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;
      ::v-deep {
        .text {
          display: none;
        }
      }
      @media screen and (min-width: 760px) {
        ::v-deep {
          left: 10px;
          width: 120px !important;
          button {
            background: transparent !important;
            color: #1d1d1b !important;
            box-shadow: none;
            width: 120px !important;
            &:before {
              display: none;
            }
            .v-ripple__container {
              display: none !important;
            }
            .text {
              display: inline-flex;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
